import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
import get from "lodash/get"

import linkBuilder from "../../modules/linkBuilder"

/* Components */
import Layout from "../../components/layout"
import Seo from "../../components/seo/seo"
import SocialMeta from "../../components/social-meta/social-meta"
import RichTextContent from "../../components/rich-text-content/rich-text-content"
import DownloadForm from "../../components/download-form/download-form"
import DisplayTracking from "../../components/tracking/display-tracking"

/* Styles */
import {
  clean,
  cleanInner,
  logo,
  container,
  contentHeader,
  lpLeft,
  richtext,
  goToForm,
  sponsorWrapperBelowRichtext,
  hr,
  sponsor,
  sponsorHeader,
  sponsorName,
  sponsorImg,
  lpRight,
  form,
  mockupWrapper,
  mockupPosition,
  secure,
  secureLogo,
  secureText,
  sponsorWrapper,
  roundCorners,
  footer,
} from "./standaard.module.css"
import { FaExpeditedssl } from "react-icons/fa"
import MediaImage from "../../components/media-image/media-image"

class StandaardDownloadTemplate extends React.Component {
  render() {
    const article = get(this.props, "data.contentfulDownload")
    const articleDescription = get(
      article,
      "description.childMarkdownRemark.rawMarkdownBody"
    )
    const file = get(this.props, "data.file")
    // const desks = get(this.props, 'pageContext.desks')
    // const deskCategories = get(this.props, 'pageContext.deskCategories')
    const publisherLogo = get(
      this.props,
      "data.publisherLogo.childImageSharp.gatsbyImageData.images.fallback.src"
    )
    const articleUrl = `https://www.mkbservicedesk.nl${linkBuilder.download(
      article
    )}`

    const ldJson = {
      "@context": "http://schema.org",
      "@type": "NewsArticle",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": articleUrl,
      },
      headline: article.title,
      inLanguage: "nl",
      datePublished: article.publishDateInt,
      dateModified: article.updateDateInt,
      publisher: {
        "@type": "Organization",
        name: "MKB Servicedesk",
        logo: {
          "@type": "ImageObject",
          url: `https://www.mkbservicedesk.nl${publisherLogo}`,
          height: "60",
          width: "537",
        },
      },
      description: article.metaDescription || articleDescription,
    }
    if (article.image)
      ldJson.image = article.image.gatsbyImageData.images.fallback.src
    if (article.author && article.author.name) {
      ldJson.author = {
        "@type": "Person",
        name: article.author.name,
      }
      if (article.author.image)
        ldJson.author.image =
          article.author.image.gatsbyImageData.images.fallback.src
    }

    const extraOptins = {
      extraOptin1: article.extraOptin1,
      extraOptin2: article.extraOptin2,
      extraOptin1Required: article.extraOptin1Required,
      extraOptin2Required: article.extraOptin2Required,
    }

    return (
      <Layout location={this.props.location}>
        <DisplayTracking
          contentfulId={article.contentful_id}
          contentTitle={article.title}
          contentType="download"
          displayPosition="landingPage"
          displayPageContentfulId={article.contentful_id}
          displayPageTitle={article.title}
          formFields={article.formFields}
        >
          <Seo
            title={article.pageTitle || article.title}
            description={article.metaDescription || articleDescription}
            canonicalUrl={article.canonicalUrl}
            noIndex={article.noIndex}
          />
          <SocialMeta
            title={article.socialTitle || article.title}
            description={article.socialDescription || articleDescription}
            image={
              article.image &&
              article.image.gatsbyImageData &&
              article.image.gatsbyImageData.images.fallback.src
            }
            url={linkBuilder.download(article)}
          />
          <Helmet>
            <link rel="canonical" href={articleUrl} />
            <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
          </Helmet>
          <div className={`${clean} download-clean`}>
            <div className={cleanInner}>
              <div className={logo}>
                <GatsbyImage
                  image={file.childImageSharp.gatsbyImageData}
                  alt="MKB Servicedesk Logo"
                />
              </div>
              <div className={container}>
                <div className={contentHeader}>
                  <h1>{article.title}</h1>
                  <h2>{article.subtitle}</h2>
                </div>
                <div className={lpLeft}>
                  <div className={richtext}>
                    {article.downloadType === "Whitepaper" && (
                      <a className={goToForm} href="#gotoform">
                        Direct naar download
                      </a>
                    )}
                    {article.downloadType === "E-book" && (
                      <a className={goToForm} href="#gotoform">
                        Direct naar download
                      </a>
                    )}
                    {article.downloadType === "Infographic" && (
                      <a className={goToForm} href="#gotoform">
                        Direct naar download
                      </a>
                    )}
                    {article.downloadType === "Contact" && (
                      <a className={goToForm} href="#gotoform">
                        Ga naar formulier
                      </a>
                    )}
                    <RichTextContent
                      content={article.body}
                      addListMarker={true}
                    />
                    {article.sponsor && (
                      <div className={sponsorWrapperBelowRichtext}>
                        <hr className={hr} />
                        <div className={sponsor}>
                          <div className={sponsorHeader}>AANGEBODEN DOOR</div>
                          <div className={sponsorName}>
                            {article.sponsor.name}
                          </div>
                          {!article.sponsor.descriptionLong && (
                            <p>
                              {get(
                                article.sponsor,
                                "description.childMarkdownRemark.rawMarkdownBody"
                              )}
                            </p>
                          )}
                          {article.sponsor.descriptionLong && (
                            <RichTextContent
                              content={article.sponsor.descriptionLong}
                            />
                          )}
                          <div className={sponsorImg}>
                            <MediaImage image={article.sponsor.image} fadeIn={false} alt={`Logo ${article.sponsor.name}`} />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className={lpRight}>
                  <div id="gotoform" className={form}>
                    <div className={mockupWrapper}>
                      <div className={mockupPosition}>
                        {!article.mockup && (
                          <img
                            alt=""
                            src="https://images.ctfassets.net/8kzekgmzhrvm/67CypBraEMc7iEfjuyGtEY/50170791c3f1e5113271f369ce1312ca/mkbsd-whitepaper-default-image.png?w=277&h=177&q=100&fm=webp&fit=fill"
                          ></img>
                        )}
                        {article.mockup && (
                          <GatsbyImage
                            image={article.mockup.gatsbyImageData}
                            alt="Whitepaper voorbeeld"
                          />
                        )}
                      </div>
                    </div>
                    {article.downloadType === "Whitepaper" && (
                      <h2>Vul je gegevens in en download het whitepaper!</h2>
                    )}
                    {article.downloadType === "E-book" && (
                      <h2>Vul je gegevens in en download het e-book!</h2>
                    )}
                    {article.downloadType === "Infographic" && (
                      <h2>Vul je gegevens in en download de infographic!</h2>
                    )}
                    {article.downloadType === "Contact" && (
                      <h2>
                        Vul je gegevens in en wij nemen contact met je op!
                      </h2>
                    )}
                    <DownloadForm
                      contentfulId={article.contentful_id}
                      title={article.title}
                      darkBackground={false}
                      fields={article.formFields}
                      buttonText={
                        article.downloadType === "Contact"
                          ? "Verstuur je gegevens"
                          : "Download je whitepaper"
                      }
                      asset={
                        article.downloadType === "Contact"
                          ? null
                          : article.asset
                      }
                      sponsor={article.sponsor}
                      customOptin={article.customOptin}
                      extraOptins={extraOptins}
                      displayPosition="landingPage"
                      displayPageContentfulId={article.contentful_id}
                      displayPageTitle={article.title}
                      displayPagePath={linkBuilder.download(article)}
                      submitMessageTitle={article.submitMessageTitle}
                      submitMessage={article.submitMessage}
                      trackEvent={{
                        event: "website-interaction",
                        category: "call-to-action",
                        action: `download`,
                        label: `position:landingPage:clean`,
                      }}
                    />
                    <div className={secure}>
                      <span className={secureLogo}>
                        <FaExpeditedssl />
                      </span>
                      <span className={secureText}>
                        Veilige SSL verbinding, uw gegevens worden versleuteld
                        en onleesbaar verstuurd.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {article.sponsor && (
                <div className={sponsorWrapper}>
                  <hr className={hr} />
                  <div className={sponsor}>
                    <div className={sponsorHeader}>AANGEBODEN DOOR</div>
                    <div className={sponsorName}>{article.sponsor.name}</div>
                    {!article.sponsor.descriptionLong && (
                      <p>
                        {get(
                          article.sponsor,
                          "description.childMarkdownRemark.rawMarkdownBody"
                        )}
                      </p>
                    )}
                    {article.sponsor.descriptionLong && (
                      <RichTextContent
                        content={article.sponsor.descriptionLong}
                      />
                    )}
                    <div className={sponsorImg}>
                      <MediaImage image={article.sponsor.image} alt={`Logo ${article.sponsor.name}`} />
                    </div>
                  </div>
                </div>
              )}
              <div className={roundCorners}></div>
              <footer className={footer}>
                Copyright 2024 MKB Servicedesk Alle rechten zijn voorbehouden.
                Niets van de op deze website gepubliceerde gegevens mag worden
                verveelvoudigd, opgeslagen in een geautomatiseerd
                gegevensbestand en/of openbaar worden gemaakt, in enige vorm of
                op enige wijze, hetzij elektronisch, mechanisch, door
                fotokopieën, opnamen of enige andere manier, zonder
                uitdrukkelijke voorafgaande schriftelijke toestemming van MKB
                Servicedesk.
              </footer>
            </div>
          </div>
        </DisplayTracking>
      </Layout>
    )
  }
}

export default StandaardDownloadTemplate

export const pageQuery = graphql`
  query StandaardDownloadBySlug($slug: String!) {
    file(relativePath: { eq: "logo-mkbservicedesk-bubble.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 200, height: 39, quality: 100)
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    publisherLogo: file(relativePath: { eq: "logo-mkbservicedesk.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 537, height: 60, quality: 100)
      }
    }
    contentfulDownload(slug: { eq: $slug }) {
      id
      contentful_id
      downloadType
      title
      subtitle
      slug
      pageTitle
      metaDescription
      canonicalUrl
      noIndex
      mockup {
        gatsbyImageData(layout: FIXED, height: 177, quality: 100)
      }
      body {
        raw
      }
      description {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      categories {
        name
        slug
        legacyId
        priority
        desk {
          name
          slug
          legacyId
        }
      }
      sponsor {
        name
        url
        description {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        descriptionLong {
          raw
        }
        image {
          title
          gatsbyImageData(layout: CONSTRAINED, width: 200, quality: 100)
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
      submitMessageTitle
      submitMessage
      customOptin {
        raw
      }
      extraOptin1 {
        raw
      }
      extraOptin1Required
      extraOptin2 {
        raw
      }
      extraOptin2Required
      formFields
      asset {
        id
        file {
          url
          contentType
        }
      }
    }
  }
`
