// extracted by mini-css-extract-plugin
export var formCheckbox = "download-form-module--form-checkbox--186e1";
export var formField = "download-form-module--form-field--73185";
export var formFieldError = "download-form-module--form-field-error--5c036";
export var formFieldLabel = "download-form-module--form-field-label--fba37";
export var formFieldLabelOptin = "download-form-module--form-field-label-optin--7f2bd";
export var formFieldWrapper = "download-form-module--form-field-wrapper--7eea1";
export var formFieldWrapperOptin = "download-form-module--form-field-wrapper-optin--5674f";
export var formSubmit = "download-form-module--form-submit--cd3e0";
export var formSubmitLoading = "download-form-module--form-submit-loading--e04ad";
export var formWrapper = "download-form-module--form-wrapper--b3206";
export var lightBackground = "download-form-module--light-background--f969e";
export var loadingSpinner = "download-form-module--loading-spinner--f2342";
export var spin = "download-form-module--spin--8b547";
export var thankYouMessage = "download-form-module--thankYouMessage--a447f";